import {
  faShoppingCart,
  faMoneyBillWave,
  faGift,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Method from "../components/shop/method";

//import Paypal from "../components/shop/cashout/paypal";
import Litecoin from "../components/shop/cashout/litecoin";
import Solana from "../components/shop/cashout/solana";
import BTCCash from "../components/shop/cashout/btccash";
import CSDeals from "../components/shop/cashout/csdeals";
import Bitcoin from "../components/shop/cashout/bitcoin";

import Steam from "../components/shop/cashout/steam"
import GeneralGiftcard from "../components/shop/cashout/general-giftcards";
import Discord from "../components/shop/cashout/discord";
import GambleConsent from "../components/shop/consent";
import Casesgg from "../components/shop/cashout/cases";

import { useState } from "react";

import Rp from "../components/all/rp"
import { createNotification } from "../components/api";

const Shop = ({ user, settings, ws }) => {
  const [consent, setConsent] = useState(false);
  const [showConsent, setShowConsent] = useState(false);
  const [option, setOption] = useState(0);


  const click = (num) => {
    setOption(option === num ? 0 : num);
  };

  const casesggOption = () => {
    if (!user) {
      return createNotification('error', 'You must be logged in to access this reward.');
    }

    if (user?.banned || user?.frozen) return null;


    if (!consent) {
      return setShowConsent(true);
    }

    return click(12);
  }

  return (
    <>
    {showConsent && <GambleConsent setShow={setShowConsent} user={user} setConsent={setConsent} />}
    <Rp user={user}/>
      <div className="shop-container">
        <div className="title">
          <span className="blue">
            <FontAwesomeIcon icon={faShoppingCart} />
          </span>
          <p>Shop</p>
        </div>

        <div className="methods">
          <div className="section-title">
            <p>
              <span className="blue">
                <FontAwesomeIcon icon={faMoneyBillWave} />
              </span>{" "}
              Cash &amp; Crypto
            </p>
          </div>

          <Method
            disabled={!settings?.casesgg}
            img="/companies/cases.svg"
            bg="#212528"
            active={option === 12}
            click={() => casesggOption()}
          />

          <Method
            disabled={!settings?.paypal}
            img="/companies/paypal.svg"
            bg="linear-gradient(308.21deg, #263B80 -73.3%, #25BCFF 133.19%)"
            active={option === 1}
            click={() => (user?.banned || user?.frozenn ? null : click(1))}
          />
          <Method
            disabled={!settings?.ltc}
            img="/companies/ltc.svg"
            bg="linear-gradient(180deg, #A1A4C9 -3.68%, rgba(126, 128, 154, 0.7) 100%)"
            active={option === 2}
            click={() => (user?.banned || user?.frozen ? null : click(2))}
          />
          <Method
            disabled={!settings?.btc}
            img="/companies/btc.svg"
            bg="linear-gradient(180deg, #F1A4C9 -3.68%, rgba(126, 128, 154, 0.7) 100%)"
            active={option === 11}
            click={() => (user?.banned || user?.frozen ? null : click(11))}
          />
          <Method
            disabled={!settings?.sol}
            img="/companies/sol.svg"
            bg="linear-gradient(360deg, #DA20FE 0%, #08F5A7 100%)"
            active={option === 3}
            click={() => (user?.banned || user?.frozen ? null : click(3))}
          />
          <Method
            disabled={!settings?.bch}
            img="/companies/btccash.svg"
            bg="linear-gradient(180deg, #0AC18E -3.68%, #177D3E 100%)"
            active={option === 4}
            click={() => (user?.banned || user?.frozen ? null : click(4))}
          />
          <Method
            disabled={!settings?.visa}
            img="/companies/visa.svg"
            bg="white"
            active={option === 5}
            click={() => (user?.banned || user?.frozen ? null : click(5))}
          />
        </div>

        <div className="methods">
          <div className="section-title">
            <p>
              <span className="blue">
                <FontAwesomeIcon icon={faGift} />
              </span>{" "}
              Gift cards
            </p>
          </div>

          <Method
            disabled={!settings?.roblox}
            img="/companies/roblox.svg"
            bg="#EA352C"
            active={option === 6}
            click={() => (user?.banned || user?.frozen ? null : click(6))}
          />
          
          <Method
            disabled={!settings?.nitro}
            img="/companies/nitro.svg"
            bg="#868FE9"
            active={option === 8}
            click={() => (user?.banned || user?.frozen ? null : click(8))}
          />
          <Method
            disabled={!settings?.amazon}
            img="/companies/amazon.svg"
            bg="white"
            active={option === 9}
            click={() => (user?.banned || user?.frozen ? null : click(9))}
          />
          <Method
            disabled={!settings?.steam}
            img="/companies/steam.svg"
            bg="#393C74"
            active={option === 10}
            click={() => (user?.banned || user?.frozen ? null : click(10))}
          />
        </div>

        {option === 1 ? (
          <GeneralGiftcard img="/companies/paypal.svg" 
          bg="linear-gradient(308.21deg, #263B80 -73.3%, #25BCFF 133.19%)"
          //fee={0.1}
          amounts={[10000, 20000, 25000, 30000]}
          key="paypal"
          type="paypal"/>
        ) : option === 2 ? (
          <Litecoin img="/companies/ltc.svg" />
        ) : option === 3 ? (
          <Solana img="/companies/sol.svg" />
        ) : option === 4 ? (
          <BTCCash img="/companies/btccash.svg" />
        ) : option === 5 ? (
          <GeneralGiftcard
            img="/companies/visa.svg"
            bg="white"
            fee={0.1}
            key="visa"
            type="visa"
          />
        ) : option === 6 ? (
          <GeneralGiftcard
            img="/companies/roblox.svg"
            bg="#EA352C"
            fee={0}
            key="roblox"
            amounts={[10000, 20000, 40000, 60000]}
            type="roblox"
          />
        ) : option === 7 ? (
          <CSDeals img="/companies/csdeals.svg" />
        ) : option === 8 ? (
          <Discord />
        ) : option === 9 ? (
          <GeneralGiftcard
            img="/companies/amazon.svg"
            bg="white"
            fee={0}
            key="amazon"
            type="amazon"
          />
        ) : option === 10 ? (
          <Steam
            img="/companies/steam.svg"
            bg="#393C74"
            fee={0}
            key="steam"
            type="steam"
          />
         ): option === 11 ? (
            <Bitcoin img="/companies/ltc.svg" />
        ) : option === 12 ? (
          <Casesgg img="/companies/cases.svg" />
        ) : null}
            
      </div>

      <style jsx>{`
        .shop-container {
          width: 100%;
          height: 100%;

          padding: 50px 200px;

          overflow-x: hidden;
          overflow-y: auto;
        }

        .shop-container::-webkit-scrollbar {
          display: none;
        }

        .title {
          font-weight: 500;
          font-size: 24px;

          gap: 10px;
          display: flex;
          align-items: center;

          margin-bottom: 75px;
        }

        .title p {
          margin: unset;
        }

        .blue {
          color: var(--highlight-color);
        }

        .section-title {
          position: absolute;
          background: var(--dark-background);
          border-radius: 12px 12px 0px 0px;
          padding: 3px 10px;
          height: 30px;

          top: -30px;
          left: 0;
        }

        .section-title p {
          margin: unset;
        }

        .methods {
          background: var(--dark-background);
          width: 100%;
          border-radius: 0px 12px 12px 12px;

          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
          grid-gap: 15px;
          padding: 15px;

          position: relative;

          margin-bottom: 75px;
        }

        @media only screen and (max-width: 1100px) {
          .shop-container {
            padding: 50px 100px;
          }
        }

        @media only screen and (max-width: 870px) {
          .shop-container {
            padding: 50px;
          }
        }

        @media only screen and (max-width: 870px) {
          .shop-container {
            padding: 50px;
          }
        }

        @media only screen and (max-width: 400px) {
          .shop-container {
            padding: 50px 25px;
          }
        }

        @media only screen and (max-width: 290px) {
          .shop-container {
            padding: 50px 0px;
          }
        }
      `}</style>
    </>
  );
};

export default Shop;
