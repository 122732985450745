const GambleConsent = ({user, setShow, setConsent}) => {
    const confirmConsent = () => {
        setConsent(true)
        setShow(false)
    }

    return (
        <>
            <div className='rewards-modal' onClick={(e) => { setShow(false) }}>
                <div className='rewards-container' onClick={(e) => e.stopPropagation()}>
                    <p className='close' onClick={(e) => { setShow(false) }}>x</p>

                    <p className='title gold'>Gambling Warning!</p>

                    {/* <p style={{marginTop: '8px'}}>What is your birth date?</p>
                    <div className='settings-section'>
                        <input value={date} onChange={(e) => setDate(e.target.value)} type='date'/>
                        <button onClick={() => {
                            backendRequestAndNotification('/user/confirm-age', 'POST', {date}) && setShow(false)
                        }}>Confirm</button>
                    </div> */}

                    <p style={{textAlign:'center', marginTop: '1rem'}}>
                    Do not gamble if you are under the age of 18. Do not gamble if you have been addicted to gambling in past. The house always wins. Chances are you're about to lose. If the fun stops, you stop. Gamble responsibly. 
                    <br/><br/>
                    If you wish to be banned from this withdrawal option, please open a ticket with GrindBux support. 
                    </p>

                    <a href="https://www.begambleaware.org/" 
                    target="_blank" rel="noreferrer" style={{color: '#f87944', display: 'block', width: '100%', textAlign:'center'}}>https://www.begambleaware.org/</a>

                    <button className="confirm" onClick={() => confirmConsent()}>Confirm</button>
                </div>
            </div>

            <style jsx>
                {`
                    .rewards-modal {
                        position: fixed;
                        height: 100%;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: rgba(0,0,0,0.3);
                        z-index: 1001;
                        top: 0;
                        left: 0;
                    }

                    .settings-section {
                        display: flex;
                        flex: 1;
                        height: 75px;
                        background-color: #141A31;
                        border-radius: 12px;
                        padding: 15px;
                        gap: 15px;
                    }
    
                    .settings-section input {
                        border: unset;
                        outline: unset;
                        width: 100%;
                        color: white;
                        background-color: #21284C;
                        padding: 0px 10px;
                        border-radius: 6px;
                        height: 45px;
                    }
    
                    .confirm {
                        margin-top: 1rem;
                        border: unset;
                        outline: unset;
                        width: 100%;
                        height: 45px;
                        color: white;
                        background-color: #f87944;
                        border-radius: 6px;
                        cursor: pointer;
                    }

                    .title {
                        font-size: 16px;
                        font-weight: 500;
                    }

                    .gold {
                        color: red;
                        text-align: center;
                        font-size: 24px;
                    }

                    .green {
                        color: #63D68C;
                    }

                    .rewards-container {
                        position: relative;
                        height: fit-content;
                        width: 600px;
                        background: #591d07;
                        border-radius: 8px;

                        position: relative;

                        display: flex;
                        flex-direction: column;

                        padding: 24px;
                        box-sizing: border-box;
                        gap: 12px;

                        overflow: hidden;
                    }

                    .close {
                        font-weight: 700;
                        font-size: 12px;
                        position: absolute;
                        top: 8px;
                        right: 8px;
                        cursor: pointer;
                    }

                    .bar-header, .header {
                        display: flex;
                        justify-content: space-between;

                        font-weight: 600;
                        font-size: 14px;

                        width: 100%;
                    }

                    .bar-container {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        gap: 4px;
                    }

                    .bar {
                        width: 100%;
                        height: 5px;
                        background-color: #171D39;
                        border-radius: 2525px;
                        overflow: hidden;
                    }



                    p {
                        margin: unset;
                    }
                `}
            </style>
        </>
    )
}

export default GambleConsent